////////////////////////////////////////////////////
//// PORTSMOUTH student/employer theme!
////////////////////////////////////////////////////

// Romeo :: colours and banner/logo variables for the student/employer interface
$romeo-primary:                 #652c90;
$romeo-secondary:               #00a0ff;
$romeo-tertiary:                #A5D065;
$romeo-quaternary:              #90908F;
$primary:                       $romeo-primary;
$secondary:                     $romeo-secondary;
// navigation
$romeo-nav-items-hover:         $romeo-secondary;
// tables
$romeo-table-header-bg-color:   $romeo-secondary;


$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary":          $romeo-primary,
    "secondary":        $romeo-secondary,
    "tertiary":         $romeo-tertiary,
    "quaternary":       $romeo-quaternary,
    "success":          $success,
    "success-muted":    $success-muted,
    "info":             $info,
    "info-muted":       $info-muted,
    "warning":          $warning,
    "warning-muted":    $warning-muted,
    "danger":           $danger,
    "danger-muted":     $danger-muted,
    "light":            $light,
    "dark":             $dark,
    "default":          $default,
    "inferno-primary":  $inferno-primary,
    "inferno-secondary":$inferno-secondary
  ),
  $theme-colors
);
// Link colour for unauth pages
$link-color:            $romeo-primary;
$link-hover-color:      $romeo-secondary;